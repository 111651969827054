import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
} from '../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <main id="reservation" className="under">
        <div id="reservationMv" className="underMv">
          <div className="mvTit"><div className="mvTitIn">
            <h2 className="small">ご宿泊当日のお手続き</h2>
            <p>How to Check-in</p>
          </div></div>
        </div>
        <div id="reservationlistWrap">
          <ol id="reservationlist">
            <li id="reservationlist01" className="reservationlistItem">
              <div className="reservationlistTitArea">
                <div className="count">01</div>
                <div className="reservationlistTit">
                  <h3>チェックイン</h3>
                </div>
              </div>
              <div className="cont">
                <p className="box">チェックインのお手続きは、「ザ ロイヤルパークホテル アイコニック 京都」フロントにて行います。<br />
                  お宿に行かれる前に、必ずお立ち寄りください。お宿に直接行かれても入室できません。</p>
                <p>
                  <strong>チェックイン場所（受付時間 9:00～18:00）</strong><br />
                  <a href="https://www.royalparkhotels.co.jp/ic/kyoto/" target="_blank">ザ ロイヤルパークホテル アイコニック 京都</a><br />
                  TEL 075-241-1188<br />
                  <br />
                  &lt;アクセス&gt;<br />
                  ・京都市営地下鉄烏丸線・東西線「烏丸御池」駅（1番出口） 徒歩約2分<br />
                  ・駐車場はございません。車でお越しの場合は、車寄せに一時停車ください。<br />
                  京都市中京区船屋町420<br />

                  <a href="https://www.google.com/maps/place/%E3%82%B6+%E3%83%AD%E3%82%A4%E3%83%A4%E3%83%AB%E3%83%91%E3%83%BC%E3%82%AF%E3%83%9B%E3%83%86%E3%83%AB+%E3%82%A2%E3%82%A4%E3%82%B3%E3%83%8B%E3%83%83%E3%82%AF+%E4%BA%AC%E9%83%BD/@35.0111128,135.7590537,17z/data=!4m20!1m10!3m9!1s0x60010908fc7e4225:0x1727d862bb9cadba!2z44K2IOODreOCpOODpOODq-ODkeODvOOCr-ODm-ODhuODqyDjgqLjgqTjgrPjg4vjg4Pjgq8g5Lqs6YO9!5m2!4m1!1i2!8m2!3d35.0111128!4d135.7612477!16s%2Fg%2F11p65lhx60!3m8!1s0x60010908fc7e4225:0x1727d862bb9cadba!5m2!4m1!1i2!8m2!3d35.0111128!4d135.7612477!16s%2Fg%2F11p65lhx60?hl=ja" target="_blank">Google Maps</a>

                </p>
                <p><strong>お荷物お預かり</strong><br />
                  「ザ ロイヤルパークホテル アイコニック 京都」でのお預りは可能です。<strong>お宿への運搬はいたしません。</strong></p>
              </div>
            </li>
            <li id="reservationlist02" className="reservationlistItem">
              <div className="reservationlistTitArea">
                <div className="count">02</div>
                <div className="reservationlistTit">
                  <h3>お宿入室</h3>
                </div>
              </div>
              <div className="cont">
                <p>16:00以降、ご入室いただけます。<br />
                  お宿まではお客様ご自身でご移動ください。</p>
              </div>
            </li>
            <li id="reservationlist03" className="reservationlistItem">
              <div className="reservationlistTitArea">
                <div className="count">03</div>
                <div className="reservationlistTit">
                  <h3>チェックアウト</h3>
                </div>
              </div>
              <div className="cont">
                <p>チェックアウトは11:00です。</p>
                <p>電子ロックを導入しており、11:00までにお宿をご退出されることでチェックアウト完了となります。<br />
                  ザ ロイヤルパークホテル アイコニック 京都へのお立ち寄りは不要です。<br />
                  <strong>11:10に清掃担当者が入室いたします。<br />
                    レイトチェックアウトは承っておりません。</strong></p>
              </div>
            </li>
          </ol>
        </div>
      </main>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
